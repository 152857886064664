<template>
    <div class="container">
        <div class="header-btn">
            <div>
                <el-button type="primary" @click="outerVisible = true">添加新卡券</el-button>
                <el-dialog v-model="outerVisible" title="卡券基本信息" center>
                    <template #default>
                        <el-dialog v-model="innerVisible" width="30%" title="Inner Dialog" append-to-body />

                        <el-card shadow="never" class="card-border">
                            <template #header>
                                <div class="card-header">
                                    <span>基本信息</span>
                                </div>
                            </template>
                            <div class="card-content">
                                <el-form-item label="卡券类型:" style="width:292px">
                                    <el-select placeholder="全部" clearable>
                                        <el-option :value="0" label="满减券" />
                                    </el-select>
                                </el-form-item>
                            </div>
                        </el-card>

                        <el-card shadow="never" class="card-border">
                            <template #header>
                                <div class="card-header">
                                    <span>关联商品</span>
                                </div>
                            </template>
                            <div class="card-content">
                                <el-form-item label="商品ID:" style="width:292px">
                                    <el-input placeholder="商品名称,多个以半角逗号分隔" />
                                </el-form-item>
                            </div>
                        </el-card>

                        <el-card shadow="never" class="card-border">
                            <template #header>
                                <div class="card-header">
                                    <span>优惠设置</span>
                                </div>
                            </template>
                            <div class="card-content">
                                <el-form-item label="设置要求额度：" style="width:350px">
                                    <el-input placeholder="如100,则为满100或以上可用该卷" />
                                </el-form-item>
                                <el-form-item label="设置优惠金额：" style="width:350px">
                                    <el-input placeholder="设置固定优惠金额" />
                                </el-form-item>
                            </div>
                        </el-card>

                        <el-card shadow="never" class="card-border">
                            <template #header>
                                <div class="card-header">
                                    <span>卡券有效时间设置</span>
                                </div>
                            </template>
                            <div class="card-content">
                                <span>卡券上架时间:</span>
                                <el-time-picker is-range range-separator="至" start-placeholder="Start time"
                                    end-placeholder="End time" />
                            </div>
                        </el-card>

                        <el-card shadow="never" class="card-border">
                            <template #header>
                                <div class="card-header">
                                    <span>数量设置</span>
                                </div>
                            </template>
                            <div class="card-content">
                                <el-form-item label="最大领取数量:" style="width:292px">
                                    <el-input placeholder="请输入整数" />
                                </el-form-item>
                                <el-form-item label="单人限领数量:" style="width:292px">
                                    <el-input placeholder="请输入整数" />
                                </el-form-item>
                            </div>
                        </el-card>
                    </template>
                    <template #footer>
                        <div class="dialog-footer">
                            <el-button @click="outerVisible = false">取消</el-button>
                            <el-button type="primary" @click="innerVisible = true">
                                确定
                            </el-button>
                        </div>
                    </template>
                </el-dialog>
            </div>
            <div class="header-txt">
                <span>余额：0</span>
                <span>总奖励金额：0</span>
            </div>
        </div>
    </div>
    <div class="content">
        <el-card shadow="never" class="card-border">
            <template #header>
                <div class="card-header">
                    <span>查询</span>
                </div>
            </template>
            <div>
                <el-form :inline="true" label-width="100px" label-position="right">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="卡券号:">
                                <el-input placeholder="卡券号" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="卡券状态:">
                                <el-select placeholder="全部" clearable>
                                    <el-option :value="0" label="进行中" />
                                    <el-option :value="1" label="已结束" />
                                    <el-option :value="2" label="未开始" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="10">
                        </el-col>
                        <el-col :span="4">
                            <div class="search-btn">
                                <el-button type="primary">查询</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-card>
    </div>
    <div class="detail">
        <el-card shadow="never" class="card-border">
            <template #header>
                <div class="card-header">
                    <span>订单明细</span>
                </div>
            </template>
            <div>
                <el-table stripe border style="width: 100%" fit>
                    <el-table-column prop="out_trade_no" label="卡券号">
                    </el-table-column>
                    <el-table-column prop="trade_no" label="名称">
                    </el-table-column>
                    <el-table-column prop="shanghu" label="优惠金额">
                    </el-table-column>
                    <el-table-column prop="fendian" label="发放数量">
                    </el-table-column>
                    <el-table-column prop="created_at_fat" label="有效期">
                    </el-table-column>
                    <el-table-column prop="money" label="卡券状态">
                    </el-table-column>
                    <el-table-column prop="huodong" label="预算">
                    </el-table-column>
                    <el-table-column prop="youhui" label="操作">
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination">
                <el-pagination :page-size="20" :pager-count="7" layout="prev, pager, next" :total="10">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script setup>
import { ref } from 'vue'

const outerVisible = ref(false)
const innerVisible = ref(false)
</script>

<style lang="less" scoped>
.container {
    .header-btn {
        .header-txt {
            margin-top: 10px;
            color: #8d8d8d;

            span:nth-child(2) {
                margin-left: 25px;
            }
        }

        .card-border {
            border-top: 5px solid #e7e7e7;
            margin-top: 10px;

            .card-header {
                font-size: 15px;
                font-weight: bold;
            }

            .card-content {
                margin-top: 20px;
            }
        }

        .dialog-footer button:first-child {
            margin-right: 10px;
        }

    }
}

.content {
    margin-top: 20px;

    .card-border {
        border-top: 5px solid #e7e7e7;

        .card-header {
            span {
                font-size: 15px;
                font-weight: bold;
            }
        }

    }
}

.detail {
    margin-top: 20px;

    .card-border {
        border-top: 5px solid #e7e7e7;

        .card-header {
            span {
                font-size: 15px;
                font-weight: bold;
            }
        }

        .pagination {
            display: flex;
            justify-content: flex-end;
        }
    }

}
</style>